import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '@src/environments/environment';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {PublicAccount, SingleResult} from '@app/interfaces';
import {ApiCachingService} from './api-caching.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  private isDemoBehaviourSubject = new BehaviorSubject(false);

  constructor(private http: HttpClient, private cacheApi: ApiCachingService) {
  }

  getIsDemoStream(): Observable<boolean> {
    return this.isDemoBehaviourSubject;
  }

  getUserProfile(refresh: boolean = false): Observable<SingleResult<UserProfile> | null> {
    if (refresh) {
      this.cacheApi.clearCached('getUserProfile');
    }

    return this.cacheApi.getCachedWithTTL(
      'getUserProfile',
      3600,
      this.http.get<SingleResult<UserProfile>>(`${environment.apiUrl}/api/users/me`)
        .pipe(
          catchError((error) => of(null)),
        )
    );
  }

  getPublicAccount(refresh: boolean = false): Observable<SingleResult<PublicAccount>> {
    if (refresh) {
      this.cacheApi.clearCached('UserApiService::getPublicAccount');
    }

    return this.cacheApi.getCachedWithTTL('UserApiService::getPublicAccount', 180, this.http.get<SingleResult<PublicAccount>>(`${environment.apiUrl}/api/public-account`));
  }

  getAccount(accountUuid: string = null): Observable<any> {
    let params = {};

    if (accountUuid) {
      params = {accountUuid};
    }

    return this.http.get(`${environment.apiUrl}/api/users/me/account`, {params});
  }

  inviteUser(email: string, extraUser?: boolean) {
    return this.http.post(`${environment.apiUrl}/api/users/invite`, {emails: [email], extraUsers: extraUser});
  }

  removeUser(email: string, sendMessage: boolean) {
    return this.http.post(`${environment.apiUrl}/api/users/remove-users`, {emails: [email], sendSlackMessage: sendMessage});
  }

  updateUserProfile(profile) {
    return this.http.put(`${environment.apiUrl}/api/users/me`, profile);
  }

  updatePublicAccount(profile): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/public-account`, profile);
  }

  checkIfUserExists(email: string, saveAsLead = false): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/users/check-if-user-exists`, {email, saveAsLead});
  }

  getDaysTillEndOfFreePeriod(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/days-till-end-of-trial`);
  }

  getUserProfileForm(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/users/me/form`);
  }

  getPublicAccountForm(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/form`);
  }

  saveContacts(contacts: any[]) {
    return this.http.put(`${environment.apiUrl}/api/public-account/contacts`, {contacts});
  }

  getAdministrationForm(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-form`);
  }

  getAdministrationAccountSettings(): Observable<SingleResult<any>> {
    return this.cacheApi.getCachedWithTTL(
      'getAdministrationAccountSettings',
      180,
      this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-account-settings`)
    );
  }

  getUserCountryCode(): Observable<SingleResult<string>> {
    return this.cacheApi.getCachedWithTTL(
      'getUserCountryCode',
      3600,
      this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/user-country-code`));
  }

  updateAdministration(values: { [p: string]: any }) {
    this.cacheApi.clearCache();
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-form`, values);
  }

  updateAdministrationBank(values: { [p: string]: any }) {
    this.cacheApi.clearCache();
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-bank-form`, values);
  }

  getAdministrationKontos(): Observable<SingleResult<any>> {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-kontos`);
  }

  saveAdministrationKontos(kontos: { [p: string]: any }) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/public-account/administration-kontos`, {kontos});
  }

  getFiscalizationForm() {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/public-account/fiscalization-form`);
  }

  updateFiscalization(values: { [p: string]: any }) {
    return this.http.post<SingleResult<any>>(`${environment.apiUrl}/api/public-account/fiscalization-form`, values);
  }

  switchToNonDemo() {
    this.isDemoBehaviourSubject.next(false);
  }

  sendReactivationEmail(email: string) {
    return this.http.post(`${environment.apiUrl}/api/users/send-reactivation-notification`, {email});
  }

  addUserRole(userId: string, role: string) {
    return this.http.post(`${environment.apiUrl}/api/super-admin/users/add-user-role`, {userId, role});
  }

  getActiveUsers(q: string): Observable<SingleResult<UserProfile[]>> {
    return this.http.post<SingleResult<UserProfile[]>>(`${environment.apiUrl}/api/super-admin/users/active-users`, {q});
  }

  getAccessToken() {
    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/users/access-token`);
  }
}
