import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import {environment} from '@src/environments/environment';
import {UserProfile} from '@app/interfaces/entities/user-profile';

@Injectable({
  providedIn: 'root'
})
export class LocaleRedirectService {
  constructor(
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  /**
   * Compares the user's locale with the current site's locale and redirects if necessary.
   * @param user - User profile containing the locale.
   */
  public compareUserLocaleVsSiteLocaleAndRedirect(user: UserProfile): void {
      if (window.location.href.includes(`/${this.locale}/`)) {
          this.redirectToUserLocale(user);
    }
  }

  /**
   * Redirects the user to their locale-specific URL if it differs from the current locale.
   * @param user - User profile containing the locale.
   */
  private redirectToUserLocale(user: UserProfile) {
    if (user.locale && environment.production) {
      const userLocale = user.locale.split('-')[0];

      if (userLocale !== this.locale) {
        window.location.href = window.location.href.replace(`/${this.locale}/`, `/${userLocale}/`);
      }
    }
  }
}
